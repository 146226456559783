<template>
  <div class="container mt-5">
    <div
      v-if="!isAuthenticated"
      class="row"
    >
      <div class="col-4 offset-4">
        <div class="card">
          <div class="card-header">
            <h5 class="my-0">
              Sign In
            </h5>
          </div>
          <div class="card-body">
            <form v-if="!showMFAForm">
              <TextInput
                v-model="email"
                label="Email:"
                :errors="v$.email.$errors"
                type="email"
                @blur="v$.email.$touch"
                @keydown.enter.prevent="signIn"
              />
              <TextInput
                v-model="password"
                label="Password:"
                :errors="v$.password.$errors"
                inputType="password"
                @blur="v$.password.$touch"
                @keydown.enter.prevent="signIn"
              />
            </form>
            <form
              v-else
            >
              <TextInput
                v-model="mfaCode"
                label="Security code:"
                :errors="v$.mfaCode.$errors"
                @blur="v$.mfaCode.$touch"
                @keydown.enter.prevent="signIn"
              />
            </form>
          </div>
          <div class="card-footer">
            <div class="d-flex justify-content-end">
              <button
                class="btn btn-md btn-primary"
                type="button"
                @click="signIn"
              >
                Sign In
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TextInput from '@/components/common/TextInput';
import { mapActions, mapGetters } from 'vuex';
import { roleLandingPage } from '@/components/constants';
import { useVuelidate } from '@vuelidate/core';
import { required, email, requiredIf, minLength, maxLength, numeric } from '@vuelidate/validators';

export default {
  name: 'Auth',
  components: {
    TextInput
  },
  setup() {
    return { v$: useVuelidate() };
  },
  data: () => ({
    email: '',
    password: '',
    mfaCode: null,
    showMFAForm: false
  }),
  validations() {
    return {
      email: {
        required,
        email
      },
      password: {
        required
      },
      mfaCode: {
        required: requiredIf(() => this.showMFAForm),
        minLength: minLength(6),
        maxLength: maxLength(6),
        numeric
      }
    };
  },
  computed: {
    ...mapGetters({
      userRole: 'auth/getUserRole',
      isAuthenticated: 'auth/isAuthenticated',
      redirectPath: 'auth/getRedirectPath',
    })
  },
  async mounted() {
    if (!this.isAuthenticated) {
      return;
    }
    const routeToRedirect = await this.getRouteToRedirect();
    await this.$router.push(routeToRedirect);
  },
  methods: {
    ...mapActions({
      initAuth: 'auth/initAuth',
      authenticate: 'auth/authenticate',
      setRedirectPath: 'auth/setRedirectPath'
    }),
    async getRouteToRedirect() {
      let routeToRedirect;
      if (this.redirectPath) {
        routeToRedirect = this.redirectPath;
        await this.setRedirectPath(null);
      } else {
        routeToRedirect = roleLandingPage[this.userRole] || '/user-profile';
      }

      return routeToRedirect;
    },
    async signIn() {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) {
        this.$toast.error('Form should be valid.');
        return false;
      }

      try {
        const requestBody = {
          email: this.email,
          password: this.password,
        };

        if (this.showMFAForm && this.mfaCode) {
          requestBody.mfaCode = this.mfaCode;
        }

        await this.authenticate(requestBody);

        await this.$router.push(await this.getRouteToRedirect());
      } catch (e) {
        if (e.message === 'MFA Required') {
          this.showMFAForm = true;
          return;
        }
        let message = '';
        if (e?.cause?.errorDetails?.errors) {
          message = e?.cause?.errorDetails?.errors.join('. ') + '. ';
        }

        this.$toast.show(`${message}Check your permissions or contact administrator of the portal.`, { type: 'error' });
      }
    }
  }
};
</script>
